import { defineStore } from 'pinia';

export const useFeedbackStore = defineStore('feedbacks', {
  state: () => {
    return {
      errors: {},
      feedback: {},
      feedbacks: [],
      pagination: {},
    };
  },

  actions: {
    async index() {
      return await this.axios.get('/feedbacks').then((res) => {
        this.feedbacks = res.data.data;
      });
    },
    async show(id) {
      this.errors = {};
      this.feedback = {};
      return this.axios.get(`/feedbacks/${id}`).then((res) => {
        this.feedback = res.data.data.attributes;
        return res.data.data.attributes;
      });
    },
    async new() {
      this.errors = {};
      this.feedback = {};
      return this.axios.get(`/feedbacks/new`).then((res) => {
        this.feedback = res.data.data.attributes;
      });
    },
    async create() {
      this.errors = {};
      return this.axios
        .post(`/feedbacks`, this.feedback)
        .then((res) => {
          this.feedback = res.data.data.attributes;
          return true;
        })
        .catch((error) => {
          this.errors = error.res.data.errors;
          return false;
        });
    },
    async edit(id) {
      this.errors = {};
      this.feedback = {};
      return this.axios.get(`/feedbacks/${id}/edit`).then((res) => {
        this.feedback = res.data.data.attributes;
      });
    },
    async update(id) {
      this.errors = {};
      console.log(this.feedback);
      return this.axios
        .put(`/feedbacks/${id}`, this.feedback)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          return false;
        });
    },
    async destroy(id) {
      this.errors = {};
      return this.axios
        .delete(`/feedbacks/${id}`)
        .then((res) => {
          const i = this.feedbacks.findIndex((feedback) => feedback.id === res.data.data.id);
          if (i > -1) this.feedbacks.splice(i, 1);
          return true;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          return false;
        });
    },
  },
});
