<template>
  <div class="relative omit_block">
    <p class="text" :class="{ [className]: !showMore }" :ref="setEl" v-html="text"></p>
    <span v-if="state.isShowMore" class="text operation_btn_placeholde"></span>

    <p class="text text_placeholde" :ref="setEl" v-html="text"></p>

    <span v-if="state.isShowMoreBtn" :class="btnClass" @click="setIsShowMove">{{
      operationBtnText
    }}</span>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, toRefs } from 'vue';

const props = defineProps({
  text: {
    type: String,
    default: true,
  },
  className: {
    type: String,
    default: 'omit_line1',
  },
  showAll: {
    type: Boolean,
    default: false,
  },
  btnClass: {
    type: String,
    default: null,
  },
});

const { text, className, showAll } = toRefs(props);

// console.log(showAll.value);

// 收集 元素
// 同 2.0 的 $refs 功能
const setEl = (el) => {
  elArr.value.push(el);
};

// 定义响应式变量
const elArr = ref([]);
const state = reactive({
  isShowMoreBtn: false,
  isShowMore: false,
  isShowAll: false,
});

const { isShowMoreBtn, isShowMore, isShowAll } = toRefs(state);
// console.log(isShowMore.value);

// console.log(isShowAll.value);

// 计算属性
const operationBtnText = computed(() => {
  if (showAll.value) {
    return isShowMore.value ? 'Show more' : 'Show less';
  } else {
    return !isShowMore.value ? 'Show more' : 'Show less';
  }
});

const showMore = computed({
  get: () => {
    if (showAll.value) {
      return !isShowAll.value;
    } else {
      return isShowMore.value;
    }
  },
  set: (val) => {
    // console.log('val', val);
    isShowMore.value = val;
    isShowAll.value = val;
  },
});

const btnClass = computed(() => {
  const base = ['font-medium'];
  if (props.btnClass) {
    props.btnClass;
  } else {
    base.push('absolute text-blue-800 bg-gray-100 bottom-0 right-0');
  }
  return base;
});

// console.log(showMore.value);
// console.log(state.isShowMore);

// 声明周期 挂载
onMounted(() => {
  const [text, textPlaceHolde] = elArr.value;

  if (textPlaceHolde.offsetHeight > text.offsetHeight) {
    state.isShowMoreBtn = true;
  }
});

const setIsShowMove = () => {
  // console.log('showMore1:', showMore.value);
  // console.log('isShowMore:', isShowMore.value);
  // console.log('showAll:', showAll.value);
  if (showAll.value) {
    showMore.value = !isShowAll.value;
    // console.log('showMore:', showMore.value);
  } else {
    showMore.value = !showMore.value;
  }
  // console.log('isShowMore:', isShowMore.value);
  // console.log('showMore:', showMore.value);
  // console.log('isShowAll:', isShowAll.value);
};
</script>

<style scoped>
.text_placeholde {
  content: '占位用的，用来判断是否需要显示展开按钮';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0;
  z-index: -100;
}

.operation_btn {
  position: absolute;
  right: 0;
  bottom: 0;
  /* background-color: #fff; */
}

.omit_line1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.omit_line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.omit_line3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.omit_line4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.operation_btn_placeholde {
  content: '我是占位用的, 如果收起按钮遮挡了文字就换行显示';
  display: inline-block;
  width: 32px;
  height: 20px;
  opacity: 0;
}

.flex_block {
  margin-top: 10px;
  display: flex;
  flex-flow: row nowrap;
}

.flex_block img {
  margin-right: 5px;
  width: auto;
  max-height: 100px;
}

.flex_block p {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
