import { defineStore } from 'pinia';

export const useServiceStore = defineStore('services', {
  state: () => {
    return {
      errors: {},
      service: {},
      services: [],
      apples: [],
      generics: [],
      pagination: {},
    };
  },

  actions: {
    async index() {
      return await this.axios.get('/services').then((res) => {
        this.services = res.data.data;
      });
    },
    async apple() {
      return await this.axios.get('/services/apple').then((res) => {
        this.apples = res.data.data;
      });
    },
    async generic() {
      return await this.axios.get('/services/generic').then((res) => {
        this.generics = res.data.data;
      });
    },
    async show(id) {
      this.errors = {};
      this.service = {};
      return this.axios.get(`/services/${id}`).then((res) => {
        this.service = res.data.data.attributes;
        return res.data.data.attributes;
      });
    },
    async new() {
      this.errors = {};
      this.service = {};
      return this.axios.get(`/services/new`).then((res) => {
        this.service = res.data.data.attributes;
      });
    },
    async create() {
      this.errors = {};
      return this.axios
        .post(`/services`, this.service)
        .then((res) => {
          this.service = res.data.data.attributes;
          return true;
        })
        .catch((error) => {
          this.errors = error.res.data.errors;
          return false;
        });
    },
    async edit(id) {
      this.errors = {};
      this.service = {};
      return this.axios.get(`/services/${id}/edit`).then((res) => {
        this.service = res.data.data.attributes;
      });
    },
    async update(id) {
      this.errors = {};
      console.log(this.service);
      return this.axios
        .put(`/services/${id}`, this.service)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          return false;
        });
    },
    async updateAll() {
      this.errors = {};
      this.services = [];
      return await this.axios
        .put(`/services/update_credit`, this.service)
        .then((res) => {
          this.services = res.data.data;
          return true;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          return false;
        });
    },
    async setDefaultPrices() {
      this.errors = {};
      this.services = [];
      return await this.axios
        .get(`/services/set_default_prices`)
        .then((res) => {
          // this.services = res.data.data;
          return true;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          return false;
        });
    },
    async destroy(id) {
      this.errors = {};
      return this.axios
        .delete(`/services/${id}`)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          return false;
        });
    },
  },
});
