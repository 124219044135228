import { defineStore } from 'pinia';

export const useUnlockStore = defineStore('unlocks', {
  state: () => {
    return {
      errors: {},
      unlock: {},
      unlocks: [],
      apples: [],
      generics: [],
      pagination: {},
    };
  },

  actions: {
    async index() {
      return await this.axios.get('/unlocks').then((res) => {
        this.unlocks = res.data.data;
      });
    },
    async show(id) {
      this.errors = {};
      this.unlock = {};
      return this.axios.get(`/unlocks/${id}`).then((res) => {
        this.unlock = res.data.data.attributes;
        return res.data.data.attributes;
      });
    },
    async new() {
      this.errors = {};
      this.unlock = {};
      return this.axios.get(`/unlocks/new`).then((res) => {
        this.unlock = res.data.data.attributes;
      });
    },
    async create() {
      this.errors = {};
      return this.axios
        .post(`/unlocks`, this.unlock)
        .then((res) => {
          this.unlock = res.data.data.attributes;
          return true;
        })
        .catch((error) => {
          this.errors = error.res.data.errors;
          return false;
        });
    },
    async edit(id) {
      this.errors = {};
      this.unlock = {};
      return this.axios.get(`/unlocks/${id}/edit`).then((res) => {
        console.log(res.data);
        this.unlock = res.data.data.attributes;
        return res.data.data.attributes;
      });
    },
    async update(id) {
      this.errors = {};
      return this.axios
        .put(`/unlocks/${id}`, this.unlock)
        .then((res) => {
          this.unlock = res.data.data.attributes;
          return true;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          return false;
        });
    },
    async updateAll() {
      this.errors = {};
      this.unlocks = [];
      return await this.axios
        .put(`/unlocks/update_credit`, this.unlock)
        .then((res) => {
          this.unlocks = res.data.data;
          return true;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          return false;
        });
    },
    async setDefaultPrices() {
      this.errors = {};
      this.unlocks = [];
      return await this.axios
        .get(`/unlocks/set_default_prices`)
        .then((res) => {
          // this.unlocks = res.data.data;
          return true;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          return false;
        });
    },
    async destroy(id) {
      this.errors = {};
      return this.axios
        .delete(`/unlocks/${id}`)
        .then((res) => {
          const i = this.unlocks.findIndex((unlock) => unlock.id === res.data.data.id);
          if (i > -1) this.unlocks.splice(i, 1);
          return true;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          return false;
        });
    },
  },
});
